$icomoon-font-family: "etrainu-icon-regular" !default;
$icomoon-font-path: "assets/fonts/etrainu-icons-regular" !default;

$icon-award-ribbon-star: unquote('"\\e900"');
$icon-user-search: unquote('"\\e901"');
$icon-contract-diagonal: unquote('"\\e91e"');
$icon-checkmark: unquote('"\\e91f"');
$icon-check-box-outline-blank: unquote('"\\e920"');
$icon-single-man-refresh: unquote('"\\e921"');
$icon-single-man: unquote('"\\e922"');
$icon-blood-drop: unquote('"\\e923"');
$icon-asterisk: unquote('"\\e925"');
$icon-alarm-bell: unquote('"\\e926"');
$icon-alert-circle: unquote('"\\e927"');
$icon-arrow-button-down: unquote('"\\e929"');
$icon-arrow-button-left: unquote('"\\e92a"');
$icon-arrow-button-right: unquote('"\\e92b"');
$icon-arrow-button-up: unquote('"\\e92c"');
$icon-button-play: unquote('"\\e92d"');
$icon-common-file-text: unquote('"\\e92e"');
$icon-cursor-target: unquote('"\\e92f"');
$icon-download-bottom: unquote('"\\e930"');
$icon-expand-diagonal: unquote('"\\e931"');
$icon-expand-horizontal: unquote('"\\e932"');
$icon-expand-vertical: unquote('"\\e933"');
$icon-explosion-lines: unquote('"\\e934"');
$icon-fantasy-cauldron: unquote('"\\e935"');
$icon-floppy-disk: unquote('"\\e936"');
$icon-folder-close: unquote('"\\e937"');
$icon-folder-open: unquote('"\\e938"');
$icon-information-circle: unquote('"\\e939"');
$icon-lock: unquote('"\\e93a"');
$icon-subtract: unquote('"\\e93b"');
$icon-zoom-in: unquote('"\\e93c"');
$icon-zoom-out: unquote('"\\e93d"');
$icon-add-alarm: unquote('"\\e93e"');
$icon-bin: unquote('"\\e93f"');
$icon-check-circle: unquote('"\\e940"');
$icon-delete-trash-restore: unquote('"\\e941"');
$icon-house: unquote('"\\e949"');
$icon-note-book: unquote('"\\e97d"');
$icon-passport-globe: unquote('"\\e982"');
$icon-shared-document: unquote('"\\e99c"');
$icon-partnership: unquote('"\\e99d"');
$icon-graduate-hat: unquote('"\\e99e"');
$icon-user-switch: unquote('"\\e99f"');
$icon-image-file: unquote('"\\e9a0"');
$icon-hyperlink: unquote('"\\e9a1"');
$icon-login: unquote('"\\e9a2"');
$icon-user-add: unquote('"\\e9a3"');
$icon-user-checkmark: unquote('"\\e9a4"');
$icon-credit-card: unquote('"\\e9a5"');
$icon-vimeo: unquote('"\\e9a6"');
$icon-conversation-chat: unquote('"\\e9a7"');
$icon-document-folder: unquote('"\\e9a8"');
$icon-book-library: unquote('"\\e9a9"');
$icon-book-close-bookmark: unquote('"\\e9aa"');
$icon-safety-float: unquote('"\\e9ab"');
$icon-dashboard: unquote('"\\e9ac"');
$icon-add-box: unquote('"\\e9ad"');
$icon-add: unquote('"\\e9ae"');
$icon-alert-triangle: unquote('"\\e9af"');
$icon-arrow-down: unquote('"\\e9b0"');
$icon-arrow-left: unquote('"\\e9b1"');
$icon-arrow-right: unquote('"\\e9b2"');
$icon-arrow-up: unquote('"\\e9b3"');
$icon-calendar: unquote('"\\e9b4"');
$icon-check-box: unquote('"\\e9b5"');
$icon-chevron-down: unquote('"\\e9b6"');
$icon-chevron-left: unquote('"\\e9b7"');
$icon-chevron-right: unquote('"\\e9b8"');
$icon-chevron-up: unquote('"\\e9b9"');
$icon-clear: unquote('"\\e9ba"');
$icon-cloud-upload: unquote('"\\e9bb"');
$icon-comment: unquote('"\\e9bc"');
$icon-edit: unquote('"\\e9bd"');
$icon-email-add: unquote('"\\e9be"');
$icon-email-body: unquote('"\\e9bf"');
$icon-email: unquote('"\\e9c0"');
$icon-event-busy: unquote('"\\e9c1"');
$icon-filter: unquote('"\\e9c2"');
$icon-first-page: unquote('"\\e9c3"');
$icon-flag: unquote('"\\e9c4"');
$icon-home: unquote('"\\e9c5"');
$icon-keyboard-arrow-down: unquote('"\\e9c6"');
$icon-keyboard-arrow-left: unquote('"\\e9c7"');
$icon-keyboard-arrow-next: unquote('"\\e9c8"');
$icon-keyboard-arrow-previous: unquote('"\\e9c9"');
$icon-keyboard-arrow-right: unquote('"\\e9ca"');
$icon-keyboard-arrow-up: unquote('"\\e9cb"');
$icon-last-page: unquote('"\\e9cc"');
$icon-logout: unquote('"\\e9cd"');
$icon-maps: unquote('"\\e9ce"');
$icon-menu: unquote('"\\e9cf"');
$icon-more-horizontal: unquote('"\\e9d0"');
$icon-more-vert: unquote('"\\e9d1"');
$icon-navigation-menu-horizontal: unquote('"\\e9d2"');
$icon-navigation-menu-vertical: unquote('"\\e9d3"');
$icon-person-outline: unquote('"\\e9d4"');
$icon-close: unquote('"\\e9d5"');
$icon-phone: unquote('"\\e9de"');
$icon-question-circle: unquote('"\\e9df"');
$icon-refresh: unquote('"\\e9e0"');
$icon-school: unquote('"\\e9e1"');
$icon-search: unquote('"\\e9e2"');
$icon-wifi-5m: unquote('"\\e9e3"');
$icon-wifi-30s: unquote('"\\e9e4"');
$icon-wifi-off: unquote('"\\e9e5"');
$icon-wifi-refresh: unquote('"\\e9e6"');
$icon-wifi: unquote('"\\e9e7"');
$icon-send-email: unquote('"\\e9e8"');
$icon-settings-off: unquote('"\\e9e9"');
$icon-settings-on: unquote('"\\e9ea"');
$icon-sms: unquote('"\\e9eb"');
$icon-stopwatch: unquote('"\\e9ec"');
$icon-video-call: unquote('"\\e9ed"');
$icon-view: unquote('"\\e9ee"');
$icon-wifi-1m: unquote('"\\e9ef"');
$icon-transfer-inline: unquote('"\\e9f0"');
$icon-transfer: unquote('"\\e9f1"');
$icon-undo-half: unquote('"\\e9f2"');
$icon-undo-full: unquote('"\\e9f3"');
$icon-undo-dotted: unquote('"\\e9f4"');
$icon-undo: unquote('"\\e9f5"');
$icon-heart: unquote('"\\e9f6"');
$icon-hourglass: unquote('"\\e9f7"');
$icon-ice-cream-bite: unquote('"\\e9f8"');
$icon-ice-cream: unquote('"\\e9f9"');
$icon-megaphone: unquote('"\\e9fa"');
$icon-signal: unquote('"\\e9fb"');
$icon-weather-cloud: unquote('"\\e9fc"');
$icon-wheat: unquote('"\\e9fd"');
$icon-missing-data: unquote('"\\e902"');
