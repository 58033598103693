@import 'colors';

// Font Family
$font-family-base: 'Montserrat', sans-serif;

// Font Weights
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Font Sizes
$heading1: 3rem;
$heading2: 2.25rem;
$heading3: 2rem;
$heading4: 1.75rem;
$heading5: 1.5rem;
$heading6: 1.25rem;
$heading7: 1.125rem;
$body1: 1rem;
$body2: 0.875rem;
$body3: 0.75rem;

// Font Line Heights
$line-height-body1: 1.5rem;
$line-height-body2: 1.25rem;
$line-height-body3: 1.125rem;
$line-height-heading1: 3.75rem;
$line-height-heading2: 2.75rem;
$line-height-heading3: 2.5rem;
$line-height-heading4: 2.25rem;
$line-height-heading5: 2rem;
$line-height-heading6: 1.875rem;
$line-height-heading7: 1.75rem;

// Icon Colors
$checkbox-color: $gray-200;

// Table
$table-stripe-color: #FAFAFA;


// Mixins
@mixin font-family-normal {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

@mixin font-family-bold {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

@mixin font-family-medium {
  font-family: $font-family-base;
  font-weight: $font-weight-medium;
}
